import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
    const [fullName, setFullName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [retypePassword, setRetypePassword] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [error, setError] = useState('');
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const navigate = useNavigate();

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };
    const validateUsername = (username) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(username).toLowerCase());
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (fullName.trim() === '') {
            setError('Vui lòng điền họ tên');
            return;
        }
        if (username.trim() === '') {
            setError('Vui lòng điền tên đăng nhập');
            return;
        }
        if (!validateEmail(email)) {
            setError('Email không hợp lệ');
            return;
        }
        if (password !== retypePassword) {
            setError('Mật khẩu không khớp');
            return;
        }
        if (phone.trim() === '') {
            setError('Vui lòng điền số điện thoại');
            return;
        }
        if (!recaptchaToken) {
            setError('Vui lòng xác nhận bạn không phải là robot');
            return;
        }

        try {
            const response = await axios.post('https://api.nhuadaiviet.com/api/auth/register', {
                fullName,
                username,
                email,
                password,
                phone,
                address,
                recaptchaToken,
            });

            if (response.status === 201) {
                toast.success('Đăng ký thành công, vui lòng đăng nhập');
                setTimeout(() => {
                    navigate('/login');
                }, 3000);
            }
        } catch (err) {
            if (err.response && err.response.status === 400) {
                if (err.response.data.message === 'Username already exists') {
                    setError('Tên đăng nhập đã có người sử dụng');
                } else if (err.response.data.message === 'Email already exists') {
                    setError('Email đã có người sử dụng');
                } else {
                    setError('Đăng ký thất bại. Vui lòng thử lại.');
                }
            } else {
                setError('Đăng ký thất bại. Vui lòng thử lại.');
            }
        }
    };

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    return (
        <div className="min-h-screen flex items-start justify-center pt-2 pb-20">
            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
            />
            <div className="bg-white p-6 rounded-lg w-full max-w-md">
                <h2 className="text-2xl font-bold mb-6">Đăng ký</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Họ Tên*</label>
                        <input
                            type="text"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            className="border rounded w-full py-2 px-3 text-gray-700"
                            placeholder="Ví dụ: Nguyễn Văn An"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Tên đăng nhập*
                            <p className="text-xs font-normal text-blue-500"> (viết thường, không dấu)</p></label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="border rounded w-full py-2 px-3 text-gray-700"
                            placeholder="Ví dụ: nhuadaiviet2024"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Email*</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="border rounded w-full py-2 px-3 text-gray-700"
                            placeholder="Ví dụ: nhuadaiviet@gmail.com"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Mật khẩu*
                            <p className="text-xs font-normal "> Tối thiểu 6 ký tự và tối đa 32 ký tự</p>
                            <p className="text-xs font-normal text-blue-500"> Nên sử dụng chữ in hoa và ký tự đặc
                                biệt</p>
                        </label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="border rounded w-full py-2 px-3 text-gray-700"
                            maxLength="32"
                            placeholder="Ví dụ: Matkh@u"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Nhập lại mật khẩu*</label>
                        <input
                            type="password"
                            value={retypePassword}
                            onChange={(e) => setRetypePassword(e.target.value)}
                            className="border rounded w-full py-2 px-3 text-gray-700"
                            maxLength="32"
                            placeholder="Nhập lại mật khẩu bên trên"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Điện thoại*</label>
                        <input
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            className="border rounded w-full py-2 px-3 text-gray-700"
                            maxLength="10"
                            placeholder="Ví dụ: 0865502296"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Địa chỉ</label>
                        <input
                            type="text"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            className="border rounded w-full py-2 px-3 text-gray-700"
                        />
                    </div>
                    <div className="mb-4">
                        <ReCAPTCHA
                            sitekey="6Ld8VwIqAAAAAE1SGyC0BQbLFddn3zcNlfX-21ie"
                            onChange={handleRecaptchaChange}
                        />
                    </div>
                    {error && <p className="text-red-500 text-lg italic pb-3">{error}</p>}
                    <div className="flex items-center justify-between">
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Đăng ký
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Register;
