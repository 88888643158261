// src/pages/RoomTypeList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const RoomTypeList = () => {
    const [roomTypes, setRoomTypes] = useState([]);
    const [error] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        const fetchRoomTypes = async () => {
            try {
                const token = sessionStorage.getItem('token') || localStorage.getItem('liveToken');
                const response = await axios.get('https://api.nhuadaiviet.com/api/roomTypes', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setRoomTypes(response.data);
            } catch (err) {
                sessionStorage.clear();
                navigate('/login');
            }
        };

        fetchRoomTypes();
    }, []);

    const handleRoomTypeClick = (roomType) => {
        navigate(`/roomTypes/${roomType.id}/items`, { state: { roomTypeImage: roomType.imageUrl, roomTypeName: roomType.name } });
    };

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div>
            <div className="p-4 pb-6 -mt-16 text-right">
                <div className="text-xs">Vui lòng chọn</div>
                <div className="text-xl font-bold mb-2 text-blue-500">CHỌN THEO LOẠI PHÒNG</div>
            </div>
            <div className="p-4 pb-24 -mt-4 text-right">
                <div className="grid grid-cols-2 gap-4">
                    {roomTypes.map((roomType) => (
                        <div
                            key={roomType.id}
                            className="overflow-hidden cursor-pointer"
                            onClick={() => handleRoomTypeClick(roomType)}
                        >
                            <img
                                src={`https://api.nhuadaiviet.com/uploads/${roomType.imageUrl}`}
                                alt={roomType.name}
                                className="w-full h-32 object-cover rounded-lg"
                            />
                            <div className="p-2 text-xs font-medium">{roomType.name}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RoomTypeList;
