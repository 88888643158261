import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const Setting = () => {
    const [isPublicItem, setIsPublicItem] = useState(false);
    const [initialIsPublicItem, setInitialIsPublicItem] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserSetting = async () => {
            try {
                const token = sessionStorage.getItem('token') || localStorage.getItem('liveToken');
                if (token) {
                    const decoded = jwtDecode(token);
                    setIsPublicItem(decoded.isPublicItem);
                    setInitialIsPublicItem(decoded.isPublicItem);
                } else {
                    console.error('Token not found');
                }
            } catch (error) {
                console.error('Error fetching user setting:', error);
            }
        };
        fetchUserSetting();
    }, []);

    const handleToggle = () => {
        setIsPublicItem(!isPublicItem);
    };

    const handleSave = async () => {
        try {
            const token = sessionStorage.getItem('token') || localStorage.getItem('liveToken');
            const newIsPublicItem = isPublicItem;

            await axios.put('https://api.nhuadaiviet.com/api/profile/update-is-public-item', { isPublicItem: newIsPublicItem }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            toast.success('Đã cập nhật cài đặt. Vui lòng đăng nhập lại.');

            sessionStorage.clear();
            localStorage.clear();
            // Chuyển hướng về trang đăng nhập sau khi đăng xuất
            setTimeout(() => {

                navigate('/login');
            }, 2000); // Chờ 2 giây để hiển thị thông báo trước khi chuyển hướng

        } catch (error) {
            console.error('Lỗi khi cập nhật cài đặt:', error);
            toast.error('Không thể cập nhật cài đặt.');
        }
    };

    return (
        <div className="max-w-2xl mx-auto p-4 pb-24">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{ width: "80%", left: "20%" }}
            />
            <h2 className="text-2xl font-bold mb-4">Cài đặt người dùng</h2>
            <div className="flex flex-col mb-4 bg-gray-100 rounded-xl p-3 shadow-md ">
                <label className="font-medium mb-2">Cài đặt bài viết có thể hiển thị lên ứng dụng:</label>
                <label className="inline-flex items-center cursor-pointer">
                    <input
                        type="checkbox"
                        checked={isPublicItem}
                        onChange={handleToggle}
                        className="sr-only peer"
                    />
                    <div
                        className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                        {isPublicItem ? 'Của mọi người' : 'Chỉ mình tôi'}
                    </span>
                </label>
            </div>
            <button
                onClick={handleSave}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 w-full px-4 rounded-xl focus:outline-none focus:shadow-outline"
            >
                Lưu
            </button>
        </div>
    );
};

export default Setting;
