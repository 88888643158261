import React, { useEffect, useState } from 'react';
import { LineChart, BarChart, Line, Bar, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import axios from 'axios';

const Analytics = () => {
    const [data, setData] = useState([]);
    const [viewType, setViewType] = useState('day'); // Default view type
    const [chartType, setChartType] = useState('line'); // Default chart type
    const [startDate, setStartDate] = useState(''); // State for start date
    const [endDate, setEndDate] = useState(''); // State for end date
    const token = localStorage.getItem('token');

    useEffect(() => {
        // Fetch statistics data
        const fetchData = async () => {
            try {
                let url = `https://api.nhuadaiviet.com/api/statistics?viewType=${viewType}`;
                if (viewType === 'dateRange' && startDate && endDate) {
                    url = `https://api.nhuadaiviet.com/api/statistics?startDate=${startDate}&endDate=${endDate}`;
                }
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [viewType, startDate, endDate, token]);

    const handleViewChange = (newViewType) => {
        setViewType(newViewType);
    };

    const handleChartTypeChange = () => {
        setChartType((prevType) => (prevType === 'line' ? 'bar' : 'line'));
    };

    const handleDateRangeSubmit = () => {
        if (startDate && endDate) {
            setViewType('dateRange');
        } else {
            alert('Vui lòng chọn khoảng ngày hợp lệ.');
        }
    };

    return (
        <div className="pb-24">
            <h1 className="text-center text-2xl font-bold mb-6">Analytics</h1>

            {data.length > 0 ? (
                <ResponsiveContainer width="95%" height={400}>
                    {chartType === 'line' ? (
                        <LineChart data={data}>
                            <Line type="monotone" dataKey="login_count" stroke="#ff8000" yAxisId="left" name="Lượt đăng nhập" strokeWidth={3}/>
                            <Line type="monotone" dataKey="posts_count" stroke="#0374f1" yAxisId="right" name="Bài viết mới" strokeWidth={3}/>
                            <CartesianGrid stroke="#ccc"/>
                            <XAxis
                                dataKey={viewType === 'week' ? 'week_start' : viewType === 'month' ? 'month_start' : viewType === 'year' ? 'year_start' : 'date'}
                                tickFormatter={(date) => {
                                    const dateObj = new Date(date);
                                    if (isNaN(dateObj.getTime())) {
                                        return 'Invalid Date';
                                    }
                                    if (viewType === 'week') {
                                        return `Tuần từ ${dateObj.toLocaleDateString('vi-VN', { day: '2-digit', month: '2-digit', year: 'numeric' })}`;
                                    } else if (viewType === 'month') {
                                        return dateObj.toLocaleDateString('vi-VN', { month: '2-digit', year: 'numeric' });
                                    } else if (viewType === 'year') {
                                        return dateObj.getFullYear();
                                    }
                                    return dateObj.toLocaleDateString('vi-VN', { day: '2-digit', month: '2-digit', year: 'numeric' });
                                }}
                            />
                            <YAxis yAxisId="left" orientation="left" stroke="#ff8000"/>
                            <YAxis yAxisId="right" orientation="right" stroke="#0374f1"/>
                            <Tooltip/>
                            <Legend/>
                        </LineChart>
                    ) : (
                        <BarChart data={data}>
                            <Bar dataKey="login_count" fill="#ff8000" yAxisId="left" name="Lượt đăng nhập"/>
                            <Bar dataKey="posts_count" fill="#0374f1" yAxisId="right" name="Bài viết mới"/>
                            <CartesianGrid stroke="#ccc"/>
                            <XAxis
                                dataKey={viewType === 'week' ? 'week_start' : viewType === 'month' ? 'month_start' : viewType === 'year' ? 'year_start' : 'date'}
                                tickFormatter={(date) => {
                                    const dateObj = new Date(date);
                                    if (isNaN(dateObj.getTime())) {
                                        return 'Invalid Date';
                                    }
                                    if (viewType === 'week') {
                                        return `Tuần từ ${dateObj.toLocaleDateString('vi-VN', { day: '2-digit', month: '2-digit', year: 'numeric' })}`;
                                    } else if (viewType === 'month') {
                                        return dateObj.toLocaleDateString('vi-VN', { month: '2-digit', year: 'numeric' });
                                    } else if (viewType === 'year') {
                                        return dateObj.getFullYear();
                                    }
                                    return dateObj.toLocaleDateString('vi-VN', { day: '2-digit', month: '2-digit', year: 'numeric' });
                                }}
                            />
                            <YAxis yAxisId="left" orientation="left" stroke="#ff8000"/>
                            <YAxis yAxisId="right" orientation="right" stroke="#0374f1"/>
                            <Tooltip/>
                            <Legend/>
                        </BarChart>
                    )}
                </ResponsiveContainer>
            ) : (
                <p className="text-center text-gray-500">No data available</p>
            )}

            <div className="flex flex-wrap justify-center mb-4 mt-6">
                <button onClick={() => handleViewChange('day')}
                        className="mx-2 px-4 py-2 bg-blue-500 text-white mt-2">Ngày
                </button>
                <button onClick={() => handleViewChange('week')}
                        className="mx-2 px-4 py-2 bg-blue-500 text-white mt-2">Tuần
                </button>
                <button onClick={() => handleViewChange('month')}
                        className="mx-2 px-4 py-2 bg-blue-500 text-white mt-2">Tháng
                </button>
                <button onClick={() => handleViewChange('year')}
                        className="mx-2 px-4 py-2 bg-blue-500 text-white mt-2">Năm
                </button>
                <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="mx-2 px-4 py-2 border rounded pt-2 mt-2"
                />
                <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="mx-2 px-4 py-2 border rounded mt-2"
                />
                <button onClick={handleDateRangeSubmit} className="mx-2 px-4 py-2 bg-blue-500 text-white mt-2">Xem theo khoảng ngày</button>
            </div>

            <div className="flex justify-center mb-4">
                <button onClick={handleChartTypeChange} className="px-4 py-2 bg-green-500 text-white">
                    Đổi kiểu biểu đồ ({chartType === 'line' ? 'Line' : 'Bar'})
                </button>
            </div>
        </div>
    );
};

export default Analytics;
