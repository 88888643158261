import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const { token } = useParams();
    const navigate = useNavigate();

    const handlePasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            toast.error('Mật khẩu không khớp');
            return;
        }
        try {
            await axios.post('https://api.nhuadaiviet.com/api/auth/reset-password', { token, newPassword });
            toast.success('Mật khẩu đã được đặt lại thành công, bạn sẽ được chuyển về trang đăng nhập');
            setTimeout(() => {
                navigate('/login');
            }, 3000);
        } catch (error) {
            toast.error('Error resetting password');
        }
    };

    return (
        <div className="flex justify-center items-center h-screen">
            <ToastContainer />
            <div className="w-full max-w-md">
                <h2 className="text-2xl font-bold mb-4 text-center">Đặt lại mật khẩu</h2>
                <form onSubmit={handleSubmit} className="bg-white px-8 pt-6 pb-8 mb-4">
                    <div className="mb-4 relative">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                            Mật khẩu mới
                        </label>
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            value={newPassword}
                            onChange={handlePasswordChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required
                        />
                    </div>
                    <div className="mb-4 relative">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirm-password">
                            Nhập lại mật khẩu mới
                        </label>
                        <input
                            type={showPassword ? "text" : "password"}
                            id="confirm-password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required
                        />
                    </div>
                    <div className="flex items-center mb-4">
                        <input
                            type="checkbox"
                            id="show-password"
                            checked={showPassword}
                            onChange={() => setShowPassword(!showPassword)}
                            className="mr-2 leading-tight"
                        />
                        <label htmlFor="show-password" className="text-sm text-gray-700">
                            Hiển thị mật khẩu
                        </label>
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Hoàn tất
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
